.info {
  min-height: 20rem;
  padding: 0 7rem 0 0;
  position: relative;
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .info { padding-left: 5rem; }
}

.info__item {
  margin-top: 2rem;
}

.info__item:first-child {
  margin-top: 0;
}

.info__item__title {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  font-style: italic;
  color: #8e8e8e;
  margin-bottom: 0.2rem;
}

.info__item__description {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #fff;
}
