/* ----- */
/* About */
/* ----- */

.about {
  display: inline-flex;
  margin: 0 auto;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  .about {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

/* ----------- */
/* Description */
/* ----------- */

.about__bio {
  padding: 5rem 0 0 0;
  position: relative;
  flex-grow: 1;
  max-width: 63rem;
}

.about__bio h2 {
  margin: 0 0 3rem 0;
}

.about__bio p {
  margin-bottom: 4rem;
}

.about__details {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  margin-top: 6rem;
}

@media (min-width: 1024px) {
  .about__details {
    margin-left: 9rem;
    margin-top: 12.6rem;
  }
}