.social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 1024px) {
  .social {
    margin-left: auto;
  }
}

.social__item {
  text-decoration: none;
  color: #f5d677;
  font-size: 1.7rem;
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  display: inline-block;
  margin-top: 1.5rem;
  position: relative;
  transition: color 0.2s;
}

.social__item:first-child {
  margin-top: 0;
}

.social__item::after {
  content: '';
  width: 100%;
  border-bottom: 1px solid #565656;
  position: absolute;
  top: 100%;
  left: 0;
}

.social__item:hover {
  color: #fff;
}
