* {
  box-sizing: border-box;
}

html {
  font-size: 50.5%;
}

@media (min-width: 768px) {
  html { font-size: 62.5%; }
}

@media (min-width: 1024px) {
  html { font-size: 52.5%; }
}

@media (min-width: 1100px) {
  html { font-size: 62.5%; }
}

body {
  margin: 0 0 5rem 0;
  background: #282828;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: 'Hind', sans-serif;
  font-size: 4rem;
  color: #fff;
  letter-spacing: -0.03em;
  line-height: 1;
  margin: 5rem 0;
}

h3 {
  font-size: 1.8rem;
  color: #515151;
  font-family: 'Roboto Slab', serif;
  margin: 0;
}

img {
  display: block;
}

video {
  outline: none;
}

p {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
  letter-spacing: 0.02em;
  font-size: 1.8rem;
  line-height: 1.6;
  color: #fff;
  max-width: 65rem;
}

p a {
  color: #f5d677;
  text-decoration: none;
}

p a:hover {
  color: #fff;
}

section {
  border-top: 1px solid #565656;
  margin-top: 5rem;
}

section:first-of-type {
  margin-top: 0;
  border-top: 0;
}

.wrapper {
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 5rem;
}

/* ------- */
/* Buttons */
/* ------- */

.button-1 {
  border: 1px solid #565656;
  padding: 1.4rem 2rem;
  display: block;
  border-radius: 0.4rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  color: #f5d677;
  text-decoration: none;
  transition: color 0.2s;
  text-align: center;
}

.button-1 + .button-1 {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .button-1 {
    display: inline-block;
  }

  .button-1 + .button-1 {
    margin-top: 0;
    margin-left: 1rem;
  }
  
  .button-1:hover {
    color: #fff;
  }
}

/* ------ */
/* Header */
/* ------ */

header {
  height: 43.7rem;
  background: url('./img/header-bg.svg') 50% bottom repeat-x,
    url('./img/header-bg.svg') 50.2% bottom repeat-x;
  background-size: 100rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 5rem;
}

header::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f27894, #feefcc);
  content: '';
  z-index: -1;
}

/* ---- */
/* Work */
/* ---- */

.work {
}

.work__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5rem 0 5rem 0;
}

.work__heading h2 {
  margin: 3rem 0 0 0;
}

@media (min-width: 768px) {
  .work__heading {
    flex-direction: row;
    align-items: center;
  }

  .work__heading h2 {
    margin: 0;
  }
}

/* -------- */
/* Projects */
/* -------- */

.projects {
  display: flex;
}

/* ---------- */
/* Animations */
/* ---------- */

.slide-animation::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background: linear-gradient(#545569, #3d3f4f);
  z-index: 100;
  transform: scaleX(0);
  transition: transform 0.8s, background-color 0.2s;

}

.slide-enter .slide-animation::after,
.slide-exit .slide-animation::after {
  transform: scaleX(1);
}

.slide-animation--left::after { transform-origin: top right; }
.slide-animation--right::after { transform-origin: top left; }

.slide-enter .slide-animation--left::after { transform-origin: top left; }
.slide-enter .slide-animation--right::after { transform-origin: top right; }

/*  */
/*  */
/*  */

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #3d3f4f, #545569);
  z-index: 100;
  transform: scaleY(0);
  transition: transform 0.8s;
  transform-origin: center bottom;
  pointer-events: none;
}

.curtain--active {
  transform: scaleY(1);
  transform-origin: center top;
}

.curtain--up {
  transform-origin: center top;
}

.curtain--up.curtain--active {
  transform-origin: center bottom;
}